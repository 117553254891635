// Color
$primary-color: #ff9933;
$danger-color: #ff6433;

$black-darker: #000623;
$black-dark: #191919;
$black-color: #000000;
$black-light: #4b4b4b;
$black-lighter: #afafaf;
$black-lightest: #e4e4e4;

$brown-color: #be5f00;

$white-color: #ffffff;

$background-primary: #faf9f9;
$background-black: #101010;
$background-black-light: #222222;
$background-black-lighter: #282828;

// Responsive
$tablet-width: 768px;
$desktop-width: 1025px;

// others
$max-layout-width: 1128px; // 1440 - 180 - 180 + 24 + 24
$min-padding: 24px;

$header-height: 88px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

.action {
  width: 52.7vw;
  height: calc(100% - 88px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: 2px 0 12px 0 rgba(0, 0, 0, 0.08);
  overflow-y: scroll;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &-container {
    width: 100%;
    max-width: 760px;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;

    &-wrapper {
      width: 100%;
      height: 100%;
      max-width: 712px;
      margin: 0 auto;
      padding: 80px 24px 100px 24px;

      &-back {
        display: flex;
        flex-direction: row;
        margin-bottom: 4px;
        cursor: pointer;

        &-icon {
          display: flex;
          align-items: center;
          margin-right: 12px;
          &-image {
          }
        }

        &-text {
          font-size: 20px;
          font-weight: 600;
          color: $black-light;
        }
      }

      &-content {
        max-width: 600px;
        display: flex;
        flex-direction: column;
        margin-left: 32px;

        &-title {
          font-size: 36px;
          font-weight: bold;
        }

        &-procedure {
          display: flex;
          flex-direction: row;
          margin-top: 40px;
          margin-bottom: 80px;
        }

        &-agree {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 8px;
          font-size: 18px;

          &-wrapper {
            max-width: 540px;
            margin-top: 28px;

            &-text {
              &.highlight {
                color: $primary-color;
                text-decoration: underline;
              }
            }
          }

          &-error {
            margin-left: 48px;
            color: $danger-color;
            font-size: 16px;
            font-weight: 600;
          }
        }

        &-actions {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 36px;
          max-width: 600px;

          &-comment {
            display: flex;
            flex-direction: row;

            &-marker {
              margin-right: 4px;
              font-size: 18px;
              font-weight: 600;
              color: $primary-color;
            }

            &-text {
              font-size: 18px;
            }
          }

          &-button {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            flex-grow: 1;

            &-item {
              margin-left: 16px;

              &[data-is-grow='true'] {
                flex-grow: 1;
                margin-left: 0px;
              }
            }
          }
        }
      }
    }
  }

  &-modal {
    width: 620px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;

    &-lottie {
      width: 120px;
      height: 140px;
    }

    &-title {
      margin-top: 12px;
      font-size: 36px;
      font-weight: bold;
      text-align: center;
      color: $black-dark;

      &.loading {
        margin-top: 0px;
      }
    }

    &-content {
      font-size: 18px;
      text-align: center;
      color: $black-dark;
      margin-top: 12px;
    }
  }
}
