// Color
$primary-color: #ff9933;
$danger-color: #ff6433;

$black-darker: #000623;
$black-dark: #191919;
$black-color: #000000;
$black-light: #4b4b4b;
$black-lighter: #afafaf;
$black-lightest: #e4e4e4;

$brown-color: #be5f00;

$white-color: #ffffff;

$background-primary: #faf9f9;
$background-black: #101010;
$background-black-light: #222222;
$background-black-lighter: #282828;

// Responsive
$tablet-width: 768px;
$desktop-width: 1025px;

// others
$max-layout-width: 1128px; // 1440 - 180 - 180 + 24 + 24
$min-padding: 24px;

$header-height: 88px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

.header {
  width: 100%;
  height: $header-height;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
  position: fixed;
  z-index: 1000;
  transition: transform 0.5s;

  &[data-no-shadow='true'] {
    box-shadow: none;
  }

  &[data-is-show='false'] {
    transform: translateY(-100%);
  }

  &-wrapper {
    height: 100%;
    max-width: $max-layout-width;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin: 0px auto;
    padding: 0 $min-padding;

    &__left {
      display: flex;
      flex-direction: row;
      align-items: center;

      &-logo {
        margin-right: 36px;
      }

      &-link {
        margin-right: 36px;
        font-size: 18px;
        color: $black-light;
        cursor: pointer;

        &:hover {
          color: $primary-color;
        }
      }
    }

    &__right {
      display: flex;
      flex-direction: row;
      &-button {
        height: 30px;
        margin: 0px 6px;
      }
    }
  }
}
