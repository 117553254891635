#root {
  height: 100vh;
}

html {
  height: 100vh;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  font-family: HindVadodara;
  -webkit-tap-highlight-color: transparent;
  font-weight: 300;
  -webkit-overflow-scrolling: touch;
}

body {
  height: 100vh;
  margin: 0;
  font-family: HindVadodara;
  overflow: hidden;
}

/**
* Link
*/

a {
  background-color: transparent; /* Remove the gray background on active links in IE 10. */
  -webkit-text-decoration-skip: objects; /* Remove gaps in links underline in iOS 8+ and Safari 8+. */

  &,
  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
  }

  color: black;
}

/**
* Typography
*/

/* Remove defalut margin / padding / list-style */

ul,
ol,
dl {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
