// Color
$primary-color: #ff9933;
$danger-color: #ff6433;

$black-darker: #000623;
$black-dark: #191919;
$black-color: #000000;
$black-light: #4b4b4b;
$black-lighter: #afafaf;
$black-lightest: #e4e4e4;

$brown-color: #be5f00;

$white-color: #ffffff;

$background-primary: #faf9f9;
$background-black: #101010;
$background-black-light: #222222;
$background-black-lighter: #282828;

// Responsive
$tablet-width: 768px;
$desktop-width: 1025px;

// others
$max-layout-width: 1128px; // 1440 - 180 - 180 + 24 + 24
$min-padding: 24px;

$header-height: 88px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

$border-width: 1.5px;

// types
$types: (
  primary: (
    background-color: #ffffff,
    border-color: #e4e4e4,
    focus-border-color: $primary-color,
    text-color: #000000,
  ),
  dark: (
    background-color: $black-dark,
    border-color: transparent,
    focus-border-color: transparent,
    text-color: #ffffff,
  ),
);

.input {
  width: 100%;
  height: 64px;
  border-radius: 8px;
  font-size: 20px;
  padding: 16px 24px;
  padding: 0px 24px;
  user-select: auto;
  -webkit-user-select: auto;

  &::-ms-clear {
    display: none;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder {
    color: $black-lighter;
    font-family: HindVadodara;
  }

  &:-ms-input-placeholder {
    color: $black-lighter !important;
    font-family: HindVadodara;
  }

  &:-moz-placeholder {
    color: $black-lighter;
    font-family: HindVadodara;
  }

  &::-moz-placeholder {
    color: $black-lighter;
    font-family: HindVadodara;
  }

  @each $type, $styles in $types {
    &[data-type='#{$type}'] {
      color: map-get($styles, text-color);
      background-color: map-get($styles, background-color);

      border-width: $border-width;
      border-style: solid;
      border-color: map-get($styles, border-color);

      &:focus {
        border-color: map-get($styles, focus-border-color);
      }
    }
  }
}
