// Color
$primary-color: #ff9933;
$danger-color: #ff6433;

$black-darker: #000623;
$black-dark: #191919;
$black-color: #000000;
$black-light: #4b4b4b;
$black-lighter: #afafaf;
$black-lightest: #e4e4e4;

$brown-color: #be5f00;

$white-color: #ffffff;

$background-primary: #faf9f9;
$background-black: #101010;
$background-black-light: #222222;
$background-black-lighter: #282828;

// Responsive
$tablet-width: 768px;
$desktop-width: 1025px;

// others
$max-layout-width: 1128px; // 1440 - 180 - 180 + 24 + 24
$min-padding: 24px;

$header-height: 88px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

.social-item {
  height: 80px;
  border-bottom: 1px solid $black-lightest;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__left {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 144px;

    &-icon {
      margin-right: 16px;
    }

    &-title {
      font-size: 18px;
    }
  }

  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-input {
      max-width: 240px;
      height: 44px;
      padding-left: 12px;
    }

    &-text {
      margin-right: 12px;
      font-size: 18px;
      font-weight: 500;
    }

    &-submit {
      margin-left: 12px;
      margin-right: 16px;
      color: $primary-color;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
    }

    &-remove {
      cursor: pointer;
    }
  }
}
