// Color
$primary-color: #ff9933;
$danger-color: #ff6433;

$black-darker: #000623;
$black-dark: #191919;
$black-color: #000000;
$black-light: #4b4b4b;
$black-lighter: #afafaf;
$black-lightest: #e4e4e4;

$brown-color: #be5f00;

$white-color: #ffffff;

$background-primary: #faf9f9;
$background-black: #101010;
$background-black-light: #222222;
$background-black-lighter: #282828;

// Responsive
$tablet-width: 768px;
$desktop-width: 1025px;

// others
$max-layout-width: 1128px; // 1440 - 180 - 180 + 24 + 24
$min-padding: 24px;

$header-height: 88px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

// types

.file-input {
  width: 100%;
  display: flex;
  flex-direction: column;

  &-wrapper {
    width: 100%;
    height: 64px;
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;

    padding-left: 24px;
    padding-right: 16px;

    background-color: #ffffff;
    border: 1px solid $black-lightest;

    &-element {
      display: none;
    }

    &-placeholder {
      font-size: 20px;
      color: $black-lighter;
    }

    &-upload {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;

      &-name {
        font-size: 20px;
        margin-right: 12px;
      }

      &-remove {
        cursor: pointer;
      }
    }
  }
}
