// Color
$primary-color: #ff9933;
$danger-color: #ff6433;

$black-darker: #000623;
$black-dark: #191919;
$black-color: #000000;
$black-light: #4b4b4b;
$black-lighter: #afafaf;
$black-lightest: #e4e4e4;

$brown-color: #be5f00;

$white-color: #ffffff;

$background-primary: #faf9f9;
$background-black: #101010;
$background-black-light: #222222;
$background-black-lighter: #282828;

// Responsive
$tablet-width: 768px;
$desktop-width: 1025px;

// others
$max-layout-width: 1128px; // 1440 - 180 - 180 + 24 + 24
$min-padding: 24px;

$header-height: 88px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

.card {
  width: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  position: relative;
  font-size: 12px;
  padding: 30px 0px;
  word-break: break-all;

  &[data-is-mobile='true'] {
    max-width: 375px;
    margin: 0px auto;
    flex-direction: column;
    padding-top: 40px;
  }

  &-header {
    width: 100%;
    height: 200px;
    position: absolute;
    top: 0px;
    left: 0px;

    &[data-is-mobile='true'] {
      height: 400px;
    }

    &-image {
      width: 100%;
      height: auto;
      position: absolute;
      left: 0px;
      top: 0px;

      &[data-is-mobile='true'] {
        height: 400px;
      }
    }
  }

  &-footer-image {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0px;
    bottom: 0px;

    &[data-is-mobile='true'] {
      height: 400px;
    }
  }

  &-wrapper {
    width: calc(100% - 60px);
    height: 100%;
    min-height: 340px;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 2.5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
    position: relative;
    z-index: 1;

    &[data-is-mobile='true'] {
      padding: 24px 20px 24px 20px;
      max-width: 345px;
      width: calc(100% - 30px);
    }

    &__left {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 320px;

      &-info {
        display: flex;
        flex-direction: row;
        margin-bottom: 12px;

        &[data-is-mobile='true'] {
          flex-direction: column;
          margin-bottom: 17px;
        }

        &-photo {
          width: 44px;
          min-width: 44px;
          height: 44px;
          margin-right: 10px;

          &[data-is-mobile='true'] {
            width: 76px;
            height: 76px;
            flex-direction: column;
          }

          &-image {
            width: 100%;
            height: 100%;
            border-radius: 2px;
            object-fit: cover;
            object-position: center;
          }
        }

        &-name {
          display: flex;

          &[data-is-mobile='true'] {
            max-width: 200px;
            flex-direction: column;
          }

          &-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: 2px;

            &[data-is-mobile='true'] {
              justify-content: flex-start;
              align-items: flex-start;
              margin-top: 18px;
            }

            &-english {
              display: flex;
              flex-direction: row;
              margin-right: 4px;

              &-text {
                font-size: 14px;
                font-weight: bold;
                margin-right: 4px;
                word-break: break-all;
                margin-bottom: 2px;
                line-height: 14px;

                &[data-is-mobile='true'] {
                  font-size: 17px;
                  line-height: 17px;
                  margin-bottom: 6px;
                }
              }
            }

            &-chinese {
              font-weight: bold;
              line-height: 15px;

              &[data-is-mobile='true'] {
                font-size: 15px;
                line-height: 15px;
              }
            }
          }

          &-social {
            display: flex;
            flex-direction: row;

            &[data-is-mobile='true'] {
              margin-top: 10px;
            }

            &-icon {
              margin-right: 4px;
              margin-top: 6px;

              &[data-is-mobile='true'] {
                width: 24px;
                height: 24px;
                margin-top: 0px;
                margin-right: 6px;
              }
            }
          }
        }
      }

      &-title {
        margin-bottom: 12px;

        &[data-is-mobile='true'] {
          font-size: 15px;
          margin-bottom: 12px;
        }

        &-english {
          font-weight: 500;
        }
        &-chinese {
          font-weight: 600;
        }
      }

      &-company {
        margin-bottom: 11px;
        max-width: 200px;
        padding-right: 8px;

        &[data-is-mobile='true'] {
          font-size: 14px;
          margin-bottom: 16px;
        }
      }

      &-contact {
        display: flex;
        flex-direction: column;
        padding-right: 8px;

        &[data-is-mobile='true'] {
          font-size: 14px;
        }

        &-wrapper {
          min-height: 30px;
          display: flex;
          flex-direction: row;
          align-items: center;

          &[data-is-mobile='true'] {
            min-height: 48px;
          }

          &-icon {
            margin-right: 10px;

            &[data-is-mobile='true'] {
              width: 16px;
              height: 16px;
              flex-direction: column;
              margin: 8px 20px 0px 6px;
            }
          }

          &-content {
          }
        }
      }

      &-address {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-top: 8px;
        margin-bottom: 16px;
        padding-right: 8px;

        &[data-is-mobile='true'] {
          margin-top: 16px;
        }

        &-icon {
          margin-right: 10px;
          margin-top: 2px;

          &-image {
            width: 12px;
            height: 12px;

            &[data-is-mobile='true'] {
              width: 16px;
              height: 16px;
              margin-right: 12px;
              margin-left: 4px;
            }
          }
        }

        &-wrapper {
          &[data-is-mobile='true'] {
            max-width: 224px;
            font-size: 14px;
          }

          &-text {
            line-height: 14px;
            margin-bottom: 2px;

            &[data-is-mobile='true'] {
              line-height: 17px;
              margin-bottom: 8px;
            }
          }
        }
      }

      &-wechat {
        display: flex;
        flex-direction: row;
        align-items: center;

        &[data-is-mobile='true'] {
          height: 48px;
        }

        &-icon {
          height: 100%;
          display: flex;
          align-items: center;
          margin-right: 10px;

          &[data-is-mobile='true'] {
            width: 16px;
            height: 16px;
            margin: 8px 20px 0px 6px;
          }

          &-image {
          }
        }

        &-text {
          &[data-is-mobile='true'] {
            font-size: 14px;
          }
        }
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &[data-is-mobile='true'] {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        top: 24px;
        right: 6%;
      }

      &-logo {
        width: 98px;
        height: 98px;

        &[data-is-mobile='true'] {
          max-width: 76px;
          max-height: 76px;
          margin-bottom: 4px;
        }

        &-image {
          width: 100%;
          max-height: 98px;
          object-fit: contain;

          &[data-is-mobile='true'] {
            max-height: 76px;
          }
        }
      }

      &-bottom {
        display: flex;
        flex-direction: column;

        &-qrcode {
          max-width: 98px;
          max-height: 98px;
          margin-bottom: 8px;
          border-width: 9px;
          border-style: solid;
          border-radius: 2px;

          &[data-is-mobile='true'] {
            max-width: 90px;
            max-height: 90px;
          }

          &-image {
            width: 100%;
            height: 100%;
          }
        }

        &-contact {
          display: flex;

          &[data-is-mobile='true'] {
            display: none;
          }
        }
      }
    }
  }

  &-mobile-contact {
    width: calc(100% - 30px);
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    border-radius: 5px;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.07);
    background-color: #ee222a;
    cursor: pointer;
    z-index: 1;

    &[data-is-mobile='false'] {
      display: none;
    }

    &-icon {
      margin-right: 10px;
    }

    &-text {
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
