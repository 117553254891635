@font-face {
  font-family: 'HindVadodara';
  font-style: normal;
  font-weight: 300;
  src: url('./../assets/fonts/hind-vadodara-cufonfonts/Hind-Vadodara-300.woff') format('woff');
}

@font-face {
  font-family: 'HindVadodara';
  font-style: normal;
  font-weight: 500;
  src: url('./../assets/fonts/hind-vadodara-cufonfonts/Hind-Vadodara-500.woff') format('woff');
}

@font-face {
  font-family: 'HindVadodara';
  font-style: normal;
  font-weight: 600;
  src: url('./../assets/fonts/hind-vadodara-cufonfonts/Hind-Vadodara-600.woff') format('woff');
}

@font-face {
  font-family: 'HindVadodara';
  font-style: normal;
  font-weight: 700;
  src: url('./../assets/fonts/hind-vadodara-cufonfonts/Hind-Vadodara-700.woff') format('woff');
}

@font-face {
  font-family: 'HindVadodara';
  font-style: normal;
  font-weight: 400;
  src: url('./../assets/fonts/hind-vadodara-cufonfonts/Hind-Vadodara-Regular.woff') format('woff');
}
