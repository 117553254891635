// Color
$primary-color: #ff9933;
$danger-color: #ff6433;

$black-darker: #000623;
$black-dark: #191919;
$black-color: #000000;
$black-light: #4b4b4b;
$black-lighter: #afafaf;
$black-lightest: #e4e4e4;

$brown-color: #be5f00;

$white-color: #ffffff;

$background-primary: #faf9f9;
$background-black: #101010;
$background-black-light: #222222;
$background-black-lighter: #282828;

// Responsive
$tablet-width: 768px;
$desktop-width: 1025px;

// others
$max-layout-width: 1128px; // 1440 - 180 - 180 + 24 + 24
$min-padding: 24px;

$header-height: 88px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

.modal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 9999;

  &-wrapper {
    width: 620px;
    min-height: 314px;
    background-color: #ffffff;
    border-radius: 16px;
    padding: 50px;

    &__header {
      &-title {
        font-size: 36px;
        font-weight: bold;
      }
    }

    &__body {
      width: 100%;
      min-height: 94px;
      display: flex;
      flex-direction: column;
      padding-top: 12px;

      &-content {
        width: 100%;
        font-size: 18px;
      }
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
      &-button {
        margin-left: 16px;
      }
    }
  }
}
