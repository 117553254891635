// Color
$primary-color: #ff9933;
$danger-color: #ff6433;

$black-darker: #000623;
$black-dark: #191919;
$black-color: #000000;
$black-light: #4b4b4b;
$black-lighter: #afafaf;
$black-lightest: #e4e4e4;

$brown-color: #be5f00;

$white-color: #ffffff;

$background-primary: #faf9f9;
$background-black: #101010;
$background-black-light: #222222;
$background-black-lighter: #282828;

// Responsive
$tablet-width: 768px;
$desktop-width: 1025px;

// others
$max-layout-width: 1128px; // 1440 - 180 - 180 + 24 + 24
$min-padding: 24px;

$header-height: 88px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

.phone-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;

  &-input {
    max-width: 380px;
    margin-right: 16px;
  }

  &-select {
    width: 160px;
    min-width: 132px;
    margin-right: 16px;
  }

  &-remove {
    display: flex;
    align-items: center;

    &-icon {
      cursor: pointer;
    }
  }
}
