// Color
$primary-color: #ff9933;
$danger-color: #ff6433;

$black-darker: #000623;
$black-dark: #191919;
$black-color: #000000;
$black-light: #4b4b4b;
$black-lighter: #afafaf;
$black-lightest: #e4e4e4;

$brown-color: #be5f00;

$white-color: #ffffff;

$background-primary: #faf9f9;
$background-black: #101010;
$background-black-light: #222222;
$background-black-lighter: #282828;

// Responsive
$tablet-width: 768px;
$desktop-width: 1025px;

// others
$max-layout-width: 1128px; // 1440 - 180 - 180 + 24 + 24
$min-padding: 24px;

$header-height: 88px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

.step {
  &[data-has-next='true'] {
    flex-basis: 28%;
  }

  &-order {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 64px;

    &-wrapper {
      &-number {
        width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $black-lightest;
        border-radius: 50%;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 8px;

        &[data-is-processing='true'] {
          border: 2px solid $primary-color;
        }
      }
    }

    &-line {
      width: 100%;
      margin: 0px 10px 8px 10px;
      border-top: 1px solid $black-lightest;
      max-width: 90px;

      &[data-is-doned='true'] {
        border-top: 1px solid $primary-color;
      }
    }
  }

  &-text {
    font-size: 18px;
    font-weight: 500;
    min-width: 88px;
  }
}
