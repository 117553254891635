// Color
$primary-color: #ff9933;
$danger-color: #ff6433;

$black-darker: #000623;
$black-dark: #191919;
$black-color: #000000;
$black-light: #4b4b4b;
$black-lighter: #afafaf;
$black-lightest: #e4e4e4;

$brown-color: #be5f00;

$white-color: #ffffff;

$background-primary: #faf9f9;
$background-black: #101010;
$background-black-light: #222222;
$background-black-lighter: #282828;

// Responsive
$tablet-width: 768px;
$desktop-width: 1025px;

// others
$max-layout-width: 1128px; // 1440 - 180 - 180 + 24 + 24
$min-padding: 24px;

$header-height: 88px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

$border-width: 2px;

// types
$types: (
  primary: (
    filled-true-color: $white-color,
    filled-true-border-color: $primary-color,
    filled-true-background-color: $primary-color,
    filled-false-color: $primary-color,
    filled-false-border-color: currentColor,
  ),
  danger: (
    filled-true-color: $white-color,
    filled-true-border-color: $danger-color,
    filled-true-background-color: $danger-color,
    filled-false-color: $danger-color,
    filled-false-border-color: currentColor,
  ),
);

$font-size-xs: 16px;
$font-size-sm: 20px;

// sizes
$sizes: (
  xs: (
    height: 40px,
    padding-horizontal: 18px,
    font-size: 16px,
  ),
  sm: (
    height: 64px,
    padding-horizontal: 28px,
    font-size: 20px,
  ),
);

.button {
  border-radius: 32px;
  border-width: $border-width;
  border-style: solid;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    box-shadow: 0 2px 12px 0 rgba(255, 153, 51, 0.5);
  }

  &:focus {
    outline: none;
  }

  @each $type, $styles in $types {
    &:not(:disabled)[data-type='#{$type}'] {
      &[data-is-filled='true'] {
        color: map-get($styles, filled-true-color);
        border-color: map-get($styles, filled-true-border-color);
        background-color: map-get($styles, filled-true-background-color);
      }

      &[data-is-filled='false'] {
        color: map-get($styles, filled-false-color);
        border-color: map-get($styles, filled-false-border-color);
        background-color: transparent;
      }

      &:hover {
        color: map-get($styles, filled-true-color);
        border-color: map-get($styles, filled-true-border-color);
        background-color: map-get($styles, filled-true-background-color);
      }
    }

    &:disabled[data-type='#{$type}'] {
      &[data-is-filled='true'] {
        color: map-get($styles, filled-true-color);
        border-color: map-get($styles, filled-true-border-color);
        background-color: map-get($styles, filled-true-background-color);
        opacity: 0.5;
        cursor: not-allowed;
      }

      &:hover {
        color: map-get($styles, filled-true-color);
        border-color: map-get($styles, filled-true-border-color);
        background-color: map-get($styles, filled-true-background-color);
      }
    }
  }

  @each $size, $styles in $sizes {
    &[data-size='#{$size}'] {
      padding: 0 map-get($styles, padding-horizontal);
      height: map-get($styles, height);
      font-size: map-get($styles, font-size);
    }
  }
}
