// Color
$primary-color: #ff9933;
$danger-color: #ff6433;

$black-darker: #000623;
$black-dark: #191919;
$black-color: #000000;
$black-light: #4b4b4b;
$black-lighter: #afafaf;
$black-lightest: #e4e4e4;

$brown-color: #be5f00;

$white-color: #ffffff;

$background-primary: #faf9f9;
$background-black: #101010;
$background-black-light: #222222;
$background-black-lighter: #282828;

// Responsive
$tablet-width: 768px;
$desktop-width: 1025px;

// others
$max-layout-width: 1128px; // 1440 - 180 - 180 + 24 + 24
$min-padding: 24px;

$header-height: 88px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

.overlay {
  width: 100%;
  height: 100vh;
  // For Edge
  height: 100%;
  // WebKit-based browsers will ignore this
  height: -moz-available;
  // Mozilla-based browsers will ignore this.
  height: -webkit-fill-available;
  // For WebKit-based browsers, Edge not support this
  height: stretch;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;

  &-wrapper {
    width: 100%;
    max-width: 328px;
    height: calc(var(--vh, 1vh) * 100);
    max-height: 532px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 16px;
    background-color: #ffffff;
    border-radius: 16px;

    &__image {
      width: 100%;

      @include tablet {
        width: 296px;
      }

      &-item {
        width: 100%;
      }
    }

    &__content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 0px 8px;

      @include tablet {
        padding: 0px 4px;
      }

      &-wrapper {
        width: 100%;
        text-align: center;
        padding-top: 12px;

        &-title {
          font-size: 24px;
          font-weight: bold;
          color: #191919;
          line-height: 1.33;
          margin-bottom: 12px;
          padding: 0px 8px;

          @include tablet {
            margin-bottom: 12px;
          }
        }

        &-text {
          font-size: 16px;
          text-align: center;
          color: $black-dark;
        }

        &-link {
          &-text {
            font-weight: 500;

            &.highlight {
              color: $primary-color;
              text-decoration: underline;
            }
          }
        }
      }

      &-close {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.3);
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
