// Color
$primary-color: #ff9933;
$danger-color: #ff6433;

$black-darker: #000623;
$black-dark: #191919;
$black-color: #000000;
$black-light: #4b4b4b;
$black-lighter: #afafaf;
$black-lightest: #e4e4e4;

$brown-color: #be5f00;

$white-color: #ffffff;

$background-primary: #faf9f9;
$background-black: #101010;
$background-black-light: #222222;
$background-black-lighter: #282828;

// Responsive
$tablet-width: 768px;
$desktop-width: 1025px;

// others
$max-layout-width: 1128px; // 1440 - 180 - 180 + 24 + 24
$min-padding: 24px;

$header-height: 88px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

.display {
  width: 47.2vw;
  height: calc(100% - 88px);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow-y: scroll;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &-container {
    width: 100%;
    max-width: 680px;
    display: flex;
    flex-direction: column;

    &-wrapper {
      width: 100%;
      height: 100%;
      max-width: 608px;
      margin: 0px auto;
      padding: 80px 24px 48px 24px;

      &__device {
        width: 100%;
        height: 68px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px 12px 0px 24px;
        margin-bottom: 40px;
        border-radius: 8px;
        background-color: #ffffff;

        &-title {
          font-size: 20px;
          font-weight: 600;
        }

        &-button {
          display: flex;
          flex-direction: row;
        }
      }
    }
  }
}
